import React from "react";
import BlockText from "./block-text";
import { cn } from "../lib/helpers";
import * as styles from "./team-bios.module.css";

const TeamBios = ({ teamOne, teamTwo, teamThree, teamFour, teamFive }) => (
  <div className={cn(styles.root, 'indented-copy')}>
    {teamOne && <BlockText blocks={teamOne} />}
    {teamTwo && <BlockText blocks={teamTwo} />}
    {teamThree && <BlockText blocks={teamThree} />}
    {teamFour && <BlockText blocks={teamFour} />}
    {teamFive && <BlockText blocks={teamFive} />}
  </div>
);

export default TeamBios;
