import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import { cn } from "../lib/helpers";
import FullScreenImageViewer from "./fullscreen-image-viewer";
import * as styles from "./image-composition.module.css";

const renderImage = image => {
  let img = null;
  if (image.asset) {
    const imageData = getGatsbyImageData(
      image,
      { maxWidth: 2880 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        image={imageData}
        alt={image.alt}
      />
    );
  }
  return img;
};

const ImageComposition = ({ imageOne, imageTwo }) => {
  const imageOneAsset = renderImage(imageOne);
  const imageTwoAsset = renderImage(imageTwo);

  return (
    <div className={styles.root}>
      {imageOneAsset && (
        <div className={cn(styles.image, imageOne.size !== '' ? styles[imageOne.size] : '')}>
          {imageOneAsset}
          {imageOne.caption && (
            <div className="caption">
              <p>{imageOne.caption}</p>
            </div>
          )}
          <FullScreenImageViewer image={imageOne} />
        </div>
      )}
      {imageTwoAsset && (
        <div className={cn(styles.image, imageTwo.size !== '' ? styles[imageTwo.size] : '')}>
          {imageTwoAsset}
          {imageTwo.caption && (
            <div className={cn(styles.caption, 'caption')}>
              <p>{imageTwo.caption}</p>
            </div>
          )}
          <FullScreenImageViewer image={imageTwo} />
        </div>
      )}
    </div>
  );
};

export default ImageComposition;