import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import { format } from "date-fns";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Footer from "../components/footer";

import * as styles from "../components/news.module.css";

export const query = graphql`
  query NewsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    articles: allSanityArticle (
      sort: { fields: [publishedAt], order: DESC }
      filter: { publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          summary
          headline
          link
        }
      }
    }
  }
`;

const NewsPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const articleNodes = (data || {}).articles
    ? mapEdgesToNodes(data.articles)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout invertTheme>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div className="page-content-wrapper">
        <div className={styles.articlesContainer}>
          {articleNodes && (
            <React.Fragment>
              {articleNodes.map(article => (
                <div key={article.id} className={styles.article}>
                  <div className={styles.articleHeader}>
                    <h4>{article.title}</h4>
                    <span>{format(new Date(article.publishedAt), "MMM D,")}&nbsp;&nbsp;{format(new Date(article.publishedAt), "YYYY")}</span>
                  </div>
                  {article.headline && (
                    <div className={styles.headline}>
                      <a href={article.link} target="_blank" rel="noopener noreferrer">
                        <h3>{article.headline}</h3>
                      </a>
                    </div>
                  )}
                  {article.summary && (
                    <div className={styles.summary}>
                      <p>{article.summary}</p>
                    </div>
                  )}
                  {article.link && (
                    <a className={styles.link} href={article.link} target="_blank" rel="noopener noreferrer"><span>Full article</span></a>
                  )}
                </div>
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
      <Footer hideForm hideButton />
    </Layout>
  );
};

export default NewsPage;
