import React from "react";
import BlockText from "./block-text";
import { cn } from "../lib/helpers";
import * as styles from "./text-plug.module.css";

const TextPlug = ({ text }) => (
  <div className={cn(styles.root, 'indented-copy')}>
    <BlockText blocks={text} />
  </div>
);

export default TextPlug;
