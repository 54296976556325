import React from "react";
import BlockText from "./block-text";
import Container from "./container";
import * as styles from "./quote.module.css";

const Quote = ({ quote }) => {
  return (
    <Container>
      <div className={styles.root}>
        <div className={styles.quote}>
          <BlockText blocks={quote} />
        </div>
      </div>
    </Container>
  );
};

export default Quote;