import { Link } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import Logo from "../../static/logo.inline.svg";
import { cn } from "../lib/helpers";

import * as styles from "./header.module.css";

const Header = ({ setShowNav, showNav, theme, invertTheme }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const headerEl = useRef(null);

  const handleScroll = () => {
    const scrollY = window.pageYOffset;
    const headerElHeight = headerEl.current.clientHeight;
    const winHeight = window.innerWidth > 900 ? window.innerHeight - headerElHeight : window.innerWidth - headerElHeight;

    if (scrollY >= winHeight) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={cn(styles.root, theme == 'light' || isScrolling ? styles.light : styles.dark, isScrolling || invertTheme ? styles.isScrolling : '', showNav ? styles.navIsOpen : '')} ref={headerEl}>
      <div className={styles.wrapper}>
        <div className={cn(styles.branding, showNav ? styles.isActive : '')}>
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className={cn(styles.hamburger, showNav ? styles.isActive : '')} onClick={() => setShowNav(!showNav)} aria-hidden="true">
          <div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <ul>
            <li>
              <Link to="/architecture" activeClassName={styles.isActive}><span>Architecture &amp; Design</span></Link>
            </li>
            <li>
              <Link to="/residences" activeClassName={styles.isActive}><span>Residences</span></Link>
            </li>
            <li>
              <Link to="/amenities" activeClassName={styles.isActive}><span>Amenities</span></Link>
            </li>
            <li>
              <Link to="/neighborhood" activeClassName={styles.isActive}><span>Neighborhood</span></Link>
            </li>
            <li>
              <Link to="/team" activeClassName={styles.isActive}><span>Team</span></Link>
            </li>
            <li>
              <Link to="/availability" activeClassName={styles.isActive}><span>Availability</span></Link>
            </li>
          </ul>

          <div className={cn(styles.navFootnote, 'mobile-only')}>
            <p>Upper East Side Condominium Residences<br/>by Steven Harris Architects</p>
            {/* <button type="button" className={styles.button}><Link to="/inquire"><span>Inquire</span></Link></button> */}
          </div>
        </nav>

        {/* <button type="button" className={styles.modalButton}><Link to="/inquire"><span>Inquire</span></Link></button> */}
      </div>
    </div>
  );
};

export default Header;
