import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Footer from "./footer";
import Form from "./form";

import * as styles from "./inquire-modal.module.css";

const query = graphql`
  query InformationQuery {
    info: sanityInformation {
      email
      phoneNumber
    }
  }
`;

const InquireModal = () => (
  <StaticQuery
    query={query}
    render={data => {
      const information = data.info;

      if (!data.info) {
        throw new Error(
          'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
        );
      }

      return (
        <div className={styles.root}>
          <div>
            <Form theme="light" />
            <div className={styles.footer}>
              <div className={styles.contact}>
                {information && (
                  <h3><a href={`mailto:${information.email}`}>{information.email}</a></h3>
                )}
                {information && (
                  <h3><a href={`tel:${information.phoneNumber}`}>{information.phoneNumber}</a></h3>
                )}
              </div>
              <p className={styles.development}>Development: <a href="https://www.legioninvestmentgroup.com/" target="_blank" rel="noopener noreferrer">Legion Investment Group</a></p>
              <p className={styles.marketing}>Exclusive Marketing and Sales Agent: <a href="https://www.corcoransunshine.com/" target="_blank" rel="noopener noreferrer">Corcoran Sunshine Marketing Group</a></p>
            </div>
            <Footer hideButton hideForm isModalView />
          </div>
        </div>
      );
    }}
  />
);

export default InquireModal;
