// extracted by mini-css-extract-plugin
export const container = "home-hero-module--container--3KbKs";
export const tint = "home-hero-module--tint--3BUrp";
export const videoContainer = "home-hero-module--videoContainer--1HMJN";
export const title = "home-hero-module--title--37inb";
export const video = "home-hero-module--video--k2lyI";
export const video1 = "home-hero-module--video1--2dCcN";
export const canPlay = "home-hero-module--canPlay--3D3jS";
export const video2 = "home-hero-module--video2--mnM-_";
export const logo = "home-hero-module--logo--30MNo";
export const isActive = "home-hero-module--isActive--3mJ-l";
export const description = "home-hero-module--description--3kvB9";
export const textContainer = "home-hero-module--textContainer--22kPL";
export const textTint = "home-hero-module--textTint--1se8y";
export const text = "home-hero-module--text--1WWv4";