import React from "react";
import { graphql, navigate } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BackButton from "../components/back-button";
import InquireModal from "../components/inquire-modal";

export const query = graphql`
  query InquirePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;

const InquirePage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout invertTheme>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <InquireModal />
      <BackButton />
    </Layout>
  );
};

export default InquirePage;
