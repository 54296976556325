import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import Portal from "./portal";

import * as styles from "./modal.module.css";

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        openModal: () => handleOpen(),
        closeModal: () => handleClose(),
      }
    }
  )

  const handleOpen = () => {
    setDisplay(true);
  }

  const handleClose = () => {
    setDisplay(false);
  }

  if (display) {
    return (
      <Portal>
        <div className={styles.container}>
          {props.children}
        </div>
      </Portal>
    )
  }

  return null;
});

export default Modal;