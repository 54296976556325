import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import HomeHero from "../components/home-hero";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    frontpage: sanityFrontpage {
      animatics {
        videoUrl1
        videoUrl2
      }
      _rawDescription
      _rawIntro
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const frontpage = (data || {}).frontpage;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { animatics, _rawDescription, _rawIntro } = frontpage;

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <HomeHero {...animatics} title={site.title} _rawDescription={_rawDescription} _rawIntro={_rawIntro} />
    </Layout>
  );
};

export default IndexPage;
