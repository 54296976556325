// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amenities-js": () => import("./../../../src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-architecture-js": () => import("./../../../src/pages/architecture.js" /* webpackChunkName: "component---src-pages-architecture-js" */),
  "component---src-pages-availability-js": () => import("./../../../src/pages/availability.js" /* webpackChunkName: "component---src-pages-availability-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../../../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-neighborhood-js": () => import("./../../../src/pages/neighborhood.js" /* webpackChunkName: "component---src-pages-neighborhood-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-residences-js": () => import("./../../../src/pages/residences.js" /* webpackChunkName: "component---src-pages-residences-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

