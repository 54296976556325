import React, { useState } from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, cn } from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Footer from "../components/footer";
import ApartmentModal from "../components/apartment-modal";

import * as styles from "../components/availability.module.css";

export const query = graphql`
  query AvailabilityPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    availability: sanityAvailabilityPage {
      northElevation {
        asset {
          url
        }
      }
      southElevation {
        asset {
          url
        }
      }
    }
    apartments: allSanityApartment(sort: {order: ASC, fields: price}) {
      edges {
        node {
          id
          _key
          download {
            asset {
              url
              title
            }
          }
          charges
          bedrooms
          bathrooms
          exteriorAreaSqFt
          exteriorAreaSqM
          interiorAreaSqFt
          interiorAreaSqM
          images {
            _key
            asset {
              _id
            }
            crop {
              _key
              _type
              bottom
              left
              right
              top
            }
            hotspot {
              _key
              _type
              height
              width
              x
              y
            }
          }
          northElevation {
            asset {
              url
            }
          }
          plan {
            asset {
              url
            }
          }
          price
          residence
          southElevation {
            asset {
              url
            }
          }
          taxes
        }
      }
    }
  }
`;

const formatPrice = (int) => {
  if (int > 0) {
    return (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(int)).replace('.00', '');
  } else {
    return null;
  }
}

const formatNumber = (int) => {
  if (int > 0) {
    return new Intl.NumberFormat().format(int).replace('.00', '');
  } else {
    return null;
  }
}

const AvailabilityPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const availability = (data || {}).availability;
  const apartmentNodes = (data || {}).apartments ? mapEdgesToNodes(data.apartments) : [];
  const [elevationOrientation, setElevationOrientation] = useState('south');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeApartmentId, setActiveApartmentId] = useState(null);
  const [activePrice, setActivePrice] = useState('');
  const [activeBedrooms, setActiveBedrooms] = useState('');
  const [activeBathrooms, setActiveBathrooms] = useState('');

  let filteredApartmentNodes = apartmentNodes.filter(apartment => activeBedrooms == '' || activeBedrooms == apartment.bedrooms);
  filteredApartmentNodes = filteredApartmentNodes.filter(apartment => activeBathrooms == '' || activeBathrooms == apartment.bathrooms);
  filteredApartmentNodes = filteredApartmentNodes.filter(apartment => {
    if (activePrice == 1) {
      return apartment.price < 10000000;
    } else if (activePrice == 2) {
      return apartment.price > 10000000 && apartment.price < 20000000;
    } else if (activePrice == 3) {
      return apartment.price > 20000000 && apartment.price < 30000000;
    } else if (activePrice == 4) {
      return apartment.price > 30000000 && apartment.price < 40000000;
    } else {
      return apartment;
    }
  });
  
  const pagination = {
    link: "/",
    title: "Home"
  };

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const handleActiveApartmentId = (id) => {
    setActiveApartmentId(id);
  }

  const openModal = (id) => {
    handleActiveApartmentId(id);
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleRemoveActiveApartmentId = () => {
    if (!modalIsOpen) {
      setActiveApartmentId(null);
    }
  }

  const handleBedroomChange = (e) => {
    const newValue = e.target.value;
    setActiveBedrooms(newValue);
  }

  const handleBathroomChange = (e) => {
    const newValue = e.target.value;
    setActiveBathrooms(newValue);
  }

  const handlePriceChange = (e) => {
    const newValue = e.target.value;
    setActivePrice(newValue);
  }

  return (
    <Layout invertTheme backgroundColor>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div className={styles.root}>
        <div className={styles.main} onMouseLeave={() => handleRemoveActiveApartmentId()}>
          <div className={styles.elevation}>
            <img className={activeApartmentId === null && elevationOrientation == 'north' ? styles.isActive : ''} src={availability.northElevation.asset.url} alt={`North elevation`} />
            <img className={activeApartmentId === null && elevationOrientation == 'south' ? styles.isActive : ''} src={availability.southElevation.asset.url} alt={`South elevation`} />

            {filteredApartmentNodes.map(apartment => (
              <React.Fragment key={apartment.id}>
                <img className={activeApartmentId === apartment.id && elevationOrientation == 'north' ? styles.isActive : ''} src={apartment.northElevation && apartment.northElevation.asset ? apartment.northElevation.asset.url : availability.northElevation.asset.url} alt={`${apartment.residence} – North elevation`} />
                <img className={activeApartmentId === apartment.id && elevationOrientation == 'south' ? styles.isActive : ''} src={apartment.southElevation && apartment.southElevation.asset ? apartment.southElevation.asset.url : availability.southElevation.asset.url} alt={`${apartment.residence} – South elevation`} />
              </React.Fragment>
            ))}
            <div className={styles.elevationButtons}>
              <button className={cn(styles.button, elevationOrientation == 'south' ? styles.isActive : '')} onClick={() => setElevationOrientation('south')}><span>South Elevation</span></button>
              <button className={cn(styles.button, elevationOrientation == 'north' ? styles.isActive : '')} onClick={() => setElevationOrientation('north')}><span>North Elevation</span></button>
            </div>
          </div>
          <div className={styles.table}>
            <div className={cn(styles.tableHead, styles.tableRow)}>
              <div>Residence</div>
              <div>Bedrooms/<br/>Bathrooms</div>
              <div>Interior<br/>Sq. Ft./Sq. M.</div>
              <div>Exterior<br/>Sq. Ft./Sq. M.</div>
              <div>Common Charges/<br/>Est. Taxes</div>
              <div>Price</div>
              <div>Floor plans</div>
            </div>
            <div className={styles.apartmentFilter}>
              <div>
                <span>Bedrooms</span>
                <select onChange={(e) => handleBedroomChange(e)}>
                  <option value="">All Bedrooms</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div>
                <span>Bathrooms</span>
                <select onChange={(e) => handleBathroomChange(e)}>
                  <option value="">All Bathrooms</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>
              <div>
                <span>Price</span>
                <select onChange={(e) => handlePriceChange(e)}>
                  <option value="">All prices</option>
                  <option value="1">Less than $10m</option>
                  <option value="2">$10m &ndash; $20m</option>
                  <option value="3">$20m &ndash; $30m</option>
                  <option value="4">$30m &ndash; $40m</option>
                </select>
              </div>
            </div>
            <div className={styles.tableBody}>
              <div className={styles.mobileRules}>
                <div>
                  Residence
                  <hr />
                </div>
                <div>
                  Floorplans
                  <hr />
                </div>
              </div>
              <ul>
              {filteredApartmentNodes &&
                filteredApartmentNodes.map(node => {
                  return (
                    <li key={node.id} className={styles.tableRow} onMouseEnter={() => handleActiveApartmentId(node.id)}>
                      <div className={styles.desktopView}>
                        <div className={styles.residence}><span>{node.residence}</span></div>
                        <div><span>{node.bedrooms}{node.bedrooms && node.bathrooms ? ' / ' : ''}{node.bathrooms}</span></div>
                        <div><span>{formatNumber(node.interiorAreaSqFt)}{node.interiorAreaSqFt && node.interiorAreaSqM ? ' / ' : ''}{formatNumber(node.interiorAreaSqM)}</span></div>
                        <div><span>{formatNumber(node.exteriorAreaSqFt)}{node.exteriorAreaSqFt && node.exteriorAreaSqM ? ' / ' : ''}{formatNumber(node.exteriorAreaSqM)}</span></div>
                        <div><span>{formatPrice(node.charges)}{node.taxes && node.charges ? ' / ' : ''}{formatPrice(node.taxes)}</span></div>
                        <div>{formatPrice(node.price)}</div>
                        <div className={styles.buttons}>
                          <button className={cn(styles.button, node.plan ? '' : styles.disabled)} onClick={() => openModal(node.id)}>
                            <span>View Plan</span>
                          </button>
                          <a href={node.download ? node.download.asset.url : '#'} className={cn(styles.button, !node.download ? styles.disabled : '')} download target="_blank" rel="noopener noreferrer">
                            <span>Download</span>
                          </a>
                        </div>
                      </div>
                      <div className={styles.mobileView}>
                        <div>
                          <div className={styles.residence}><span>{node.residence}</span></div>
                          <div className={styles.bedrooms}>
                            <div>
                              <p>{node.bedrooms} bedrooms</p>
                              <p>{node.bathrooms} bathrooms</p>
                            </div>
                          </div>
                          <div className={styles.interior}>
                            {node.interiorAreaSqFt && (
                              <div>
                                <p>{formatNumber(node.interiorAreaSqFt)}{node.interiorAreaSqFt && node.interiorAreaSqM ? ' / ' : ''}{formatNumber(node.interiorAreaSqM)} interior (sqft/sqm)</p>
                              </div>
                            )}
                          </div>
                          <div className={styles.exterior}>
                            {node.exteriorAreaSqFt && (
                              <div>
                                <p>{formatNumber(node.exteriorAreaSqFt)}{node.exteriorAreaSqFt && node.exteriorAreaSqM ? ' / ' : ''}{formatNumber(node.exteriorAreaSqM)} exterior (sqft/sqm)</p>
                              </div>
                            )}
                          </div>
                          <div className={styles.charges}>
                            {node.charges && (
                              <div>
                                <p>{formatPrice(node.charges)} Est. monthly common charges</p>
                                <p>{formatPrice(node.taxes)} Est. monthly real estate taxes</p>
                              </div>
                            )}
                          </div>
                          <div>{formatPrice(node.price)}</div>
                        </div>
                        <div className={styles.buttons}>
                          <button className={cn(styles.button, node.plan ? '' : styles.disabled)} onClick={() => openModal(node.id)}>
                            <span>View Plan</span>
                          </button>
                          <a href={node.download ? node.download.asset.url : '#'} className={cn(styles.button, !node.download ? styles.disabled : '')} download target="_blank" rel="noopener noreferrer">
                            <span>Download</span>
                          </a>
                        </div>
                      </div>
                      <div className={styles.mobileRules}>
                        <div>
                          <hr />
                        </div>
                        <div>
                          <hr />
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        
        <div className={styles.footer}>
          <Footer pagination={pagination} hideForm />
        </div>
      </div>
      {modalIsOpen && (
        <ApartmentModal filteredApartmentNodes={filteredApartmentNodes} activeApartmentId={activeApartmentId} closeModal={closeModal} />
      )}
    </Layout>
  );
};

export default AvailabilityPage;
