import React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Footer from "../components/footer";

const NotFoundPage = () => (
  <Layout invertTheme>
    <SEO title="404: Not found" />
    <div className="page-content-wrapper">
      <h1>Page Not found</h1>
    </div>
    <Footer hideForm hideButton />
  </Layout>
);

export default NotFoundPage;
