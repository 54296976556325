// extracted by mini-css-extract-plugin
export const root = "header-module--root--2Pllp";
export const isScrolling = "header-module--isScrolling--42Ad2";
export const navIsOpen = "header-module--navIsOpen--1s7a_";
export const dark = "header-module--dark--1YreH";
export const modalButton = "header-module--modalButton--1L1uC";
export const light = "header-module--light--1OSSN";
export const hamburger = "header-module--hamburger--3KYjh";
export const wrapper = "header-module--wrapper--182n5";
export const branding = "header-module--branding--1PjA2";
export const isActive = "header-module--isActive--3EQlP";
export const nav = "header-module--nav--106zH";
export const showNav = "header-module--showNav--CuuOZ";
export const navFootnote = "header-module--navFootnote--3FvZR";
export const button = "header-module--button--2doAt";