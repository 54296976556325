import React, { useState, useEffect } from "react";
import { cn } from "../lib/helpers";
import BlockText from "./block-text";
import * as styles from "./home-hero.module.css";

const HomeHero = ({ videoUrl1, videoUrl2, title, _rawDescription, _rawIntro }) => {
  const [showLogo, setShowLogo] = useState(false);
  const [showUi, setShowUi] = useState(false);
  const [showText, setShowText] = useState(false);
  let videoNode1;
  let videoNode2;

  
  useEffect(() => {
    if (videoUrl1) {
      // make sure all are hidden when this runs
      setShowLogo(false);
      setShowUi(false);
      setShowText(false);
      const video1 = videoNode1;
      const video2 = videoNode2;
      // video can play
      const handleShowVideo = () => {
        video1.classList.add(`${styles.canPlay}`);
      }

      const handleHideVideo = () => {
        video1.classList.remove(`${styles.canPlay}`);
        video2.play();
        console.log("video had ended");
      }

      video1.addEventListener('canplay', handleShowVideo);
      video1.addEventListener('ended', handleHideVideo);

      const logoIn = setTimeout(() => {
        setShowLogo(true);
        clearTimeout(logoIn);
      }, 1000);

      const logoOut = setTimeout(() => {
        setShowLogo(false);
        clearTimeout(logoOut);
      }, 4000);

      const uiIn = setTimeout(() => {
        setShowUi(true);
        clearTimeout(uiIn);
      }, 6000);

      const descriptionOut = setTimeout(() => {
        setShowUi(false);
        clearTimeout(descriptionOut);
      }, 10000);

      const textIn = setTimeout(() => {
        setShowText(true);
        clearTimeout(textIn);
      }, 12000);

      return () => {
        video1.removeEventListener('canplay', handleShowVideo);
        video1.removeEventListener('ended', handleHideVideo);
      }
    }
  }, []);


  return (
    <div className={styles.container}>
      <div className={styles.videoContainer}>
        <video
          ref={node => (videoNode2 = node)}
          className={cn(styles.video2, styles.video)}
          playsInline
          loop
          muted
        >
          <source src={videoUrl2} type="video/mp4" />
        </video>
        <video
          ref={node => (videoNode1 = node)}
          className={cn(styles.video1, styles.video)}
          playsInline
          autoPlay
          muted
        >
          <source src={videoUrl1} type="video/mp4" />
        </video>
      </div>
      <div className={styles.tint}>
        <div></div>
      </div>
      <h1 className={styles.title}>
        <span>{title}</span>
        <img src="/logo.svg" alt="109 East 79" className={cn(styles.logo, showLogo ? styles.isActive : '')} />
      </h1>
      <div className={cn(styles.description, showUi ? styles.isActive : '')}>
        <BlockText blocks={_rawDescription} />
      </div>
      <div className={cn(styles.textContainer, showText ? styles.isActive : '')}>
        <div className={styles.textTint}></div>
        <div className={cn(styles.text, 'indented-copy')}>
          <BlockText blocks={_rawIntro} />
        </div>
      </div>
    </div>
  );
};

export default HomeHero;