import React from "react";
import { cn } from "../lib/helpers";
import "../styles/layout.css";
import Header from "./header";

import * as styles from "./layout.module.css";

const Layout = ({
  children, 
  setShowNav, 
  showNav,
  showHeader, 
  headerTransition, 
  invertTheme,
  backgroundColor
}) => {

  const header = () => {
    let el = null;
  
    if (headerTransition) {
      el = (
        <CSSTransition in={showHeader} timeout={200} classNames="header-transition-wrapper">
          <Header 
            setShowNav={setShowNav} 
            showNav={showNav} 
            theme={invertTheme ? 'light' : 'dark'}
            headerTransition={headerTransition}
          />
        </CSSTransition>
      );
    } else {
      el = (
        <Header 
          setShowNav={setShowNav} 
          showNav={showNav} 
          theme={invertTheme ? 'light' : 'dark'} 
          headerTransition={headerTransition}
          invertTheme={invertTheme}
        />
      )
    }
  
    return el;
  }

  return (
    <>
      {header()}
      <div className={styles.content}>{children}</div>
    </>
  );
};

export default Layout;
