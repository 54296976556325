import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Footer from "../components/footer";

import Carousel from "../components/carousel";
import TeamBios from "../components/team-bios";
import TextPlug from "../components/text-plug";

export const query = graphql`
  query TeamPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    team: sanityTeamPage {
      _rawContent
    }
  }
`;

const TeamPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const team = (data || {}).team;
  const pagination = {
    link: "/availability",
    title: "Availability"
  };


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { _rawContent } = team;

  const contentBlocks = (_rawContent || [])
  .map(c => {
    let el = null;
    switch (c._type) {
      case "carouselPlug":
        el = <Carousel key={c._key} {...c} />;
        break;
      case "textPlug":
        el = <TextPlug key={c._key} {...c} />;
        break;
      case "teamBios":
        el = <TeamBios key={c._key} {...c} />;
        break;
      default:
        el = null;
    }
    return el;
  });

  return (
    <Layout invertTheme>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div className="page-content-wrapper">
        {contentBlocks}
        <Footer pagination={pagination} hideForm />
      </div>
    </Layout>
  );
};

export default TeamPage;
