import React, { useState } from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import * as styles from "./amenity-levels.module.css";

const AmenityLevels = ({ levels, enabled }) => {
  const [activeLevel, setActiveLevel] = useState(2);

  return (
    <div className={cn(styles.root, enabled ? '' : styles.disabled)}>
      <h2 className={styles.title}>Amenity Levels</h2>
      <div className={styles.rules}>
        <hr />
        <hr />
      </div>
      <div className={styles.levels}>
        <div className={styles.text}>
          {levels && levels.map((level, index) => (
            <div key={level._key} className={cn(styles.textLevel, activeLevel === index ? styles.isActive : '')}>
              <h3>{level.title}</h3>
              <p>{level.description}</p>
            </div>
          ))}
        </div>
        <div className={styles.images}>
          {levels && levels.map((level, index) => (
            <div key={level._key} className={cn(styles.imageLevel, activeLevel === index ? styles.isActive : '')} onClick={() => setActiveLevel(index)}>
              <div>
                {level.imageDeselected && (
                  <img 
                    className={cn(styles.image, styles.imageDeselected)} 
                    src={imageUrlFor(buildImageObj(level.imageDeselected))
                      .width(1090)
                      .quality(100)
                      .url()}
                    alt="Amenity Axon" 
                  />
                )}
                {level.image && (
                  <img 
                    className={cn(styles.image, styles.imageSelected)} 
                    src={imageUrlFor(buildImageObj(level.image))
                      .width(1090)
                      .quality(100)
                      .url()}
                    alt="Amenity Axon" 
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AmenityLevels;
