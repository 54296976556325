import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Hero from "../components/hero";
import ContentContainer from "../components/content-container";
import Standfirst from "../components/standfirst";
import Footer from "../components/footer";

// content block components
import Carousel from "../components/carousel";
import ImageComposition from "../components/image-composition";
import ImageCompositionQuote from "../components/image-composition-quote";
import LargeImage from "../components/large-image";
import Map from "../components/map";
import QuoteTextRow from "../components/quote-text-row";

export const query = graphql`
  query NeighborhoodPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    neighborhood: sanityNeighborhoodPage {
      title
      _rawStandfirst
      _rawContent
      hero {
        image {
          _key
          asset {
            _id
          }
          crop {
            _key
            _type
            bottom
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
        videoUrl
      }
    }
  }
`;

const NeighborhoodPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const neighborhood = (data || {}).neighborhood;
  const pagination = {
    link: "/team",
    title: "Team"
  };

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { hero, title, _rawStandfirst, _rawContent } = neighborhood;

  const contentBlocks = (_rawContent || [])
    .map(c => {
      let el = null;
      switch (c._type) {
        case "imageComposition":
          el = <ImageComposition key={c._key} {...c} />;
          break;
        case "imageCompositionQuote":
          el = <ImageCompositionQuote key={c._key} {...c} />;
          break;
        case "quoteTextRow":
          el = <QuoteTextRow key={c._key} {...c} />;
          break;
        case "largeImage":
          el = <LargeImage key={c._key} {...c} />;
          break;
        case "carouselPlug":
          el = <Carousel key={c._key} {...c} />;
          break;
        case "map":
          el = <Map key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Hero {...hero} title={title} />
      <ContentContainer>
        <Standfirst text={_rawStandfirst}/>
        {contentBlocks}
        <Footer pagination={pagination} hideForm />
      </ContentContainer>
    </Layout>
  );
};

export default NeighborhoodPage;
