import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import { cn } from "../lib/helpers";
import BlockText from "./block-text";
import FullScreenImageViewer from "./fullscreen-image-viewer";
import * as styles from "./image-quote.module.css";

const renderImage = image => {
  let img = null;
  if (image.asset) {
    const imageData = getGatsbyImageData(
      image,
      { maxWidth: 2880 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        image={imageData}
        alt={image.alt}
        backgroundColor="#f0eeea"
      />
    );
  }
  return img;
};

const ImageQuote = ({ quote, image, switchPosition }) => {
  const imageAsset = renderImage(image);

  return (
    <div className={cn(styles.root, switchPosition ? styles.switchPos : '')}>
      {imageAsset && (
        <div className={styles.image}>
          {imageAsset}
          {image.caption && (
            <div className={cn(styles.caption, 'caption')}>
              <p>{image.caption}</p>
            </div>
          )}
          <FullScreenImageViewer image={image} />
        </div>
      )}
      <div className={styles.quote}>
        <BlockText blocks={quote} />
      </div>
    </div>
  );
};

export default ImageQuote;