import React from "react";
import BlockText from "./block-text";
import Container from "./container";
import { cn } from "../lib/helpers";
import * as styles from "./quote-text-row.module.css";

const QuoteTextRow = ({ quote, text }) => {
  return (
    <Container>
      <div className={styles.root}>
        <div className={styles.quote}>
          <BlockText blocks={quote} />
        </div>
        <div className={cn(styles.text, 'indented-copy')}>
          <BlockText blocks={text} />
        </div>
      </div>
    </Container>
  );
};

export default QuoteTextRow;