import React, { useState } from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Hero from "../components/hero";
import ContentContainer from "../components/content-container";
import Section from "../components/section";
import Standfirst from "../components/standfirst";
import Title from "../components/title";
import Footer from "../components/footer";

// content block components
import Carousel from "../components/carousel";
import ImageCompositionText from "../components/image-composition-text";
import ImageGridQuote from "../components/image-grid-quote";
import LargeImage from "../components/large-image";
import Quote from "../components/quote";
import QuoteTextRow from "../components/quote-text-row";
import Subnav from "../components/subnav";
import TextImage from "../components/text-image";



export const query = graphql`
  query ArchitecturePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    architecture: sanityArchitecturePage {
      title
      _rawStandfirst
      pageSections {
        title
        displayTitle
        _rawContent
      }
      hero {
        image {
          _key
          asset {
            _id
          }
          crop {
            _key
            _type
            bottom
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
        videoUrl
      }
    }
  }
`;

function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

const ArchitecturePage = props => {
  const { data, errors } = props;
  const [activeSection, setActiveSection] = useState(null);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const architecture = (data || {}).architecture;
  const pagination = {
    link: "/residences",
    title: "Residences"
  };

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { hero, title, _rawStandfirst, pageSections } = architecture;

  const sections = (pageSections|| [])
    .map((section, index) => {
      let contentBlocks = (section._rawContent|| [])
        .map((c) => {
          let el = null;
          switch (c._type) {
            case "imageGridQuote":
              el = <ImageGridQuote key={c._key} {...c} />;
              break;
            case "largeImage":
              el = <LargeImage key={c._key} {...c} />;
              break;
            case "imageCompositionText":
              el = <ImageCompositionText key={c._key} {...c} />;
              break;
            case "textImage":
              el = <TextImage key={c._key} {...c} />;
              break;
            case "quoteTextRow":
              el = <QuoteTextRow key={c._key} {...c} />;
              break;
            case "carouselPlug":
              el = <Carousel key={c._key} {...c} />;
              break;
            case "quotePlug":
              el = <Quote key={c._key} {...c} />;
              break;
            default:
              el = null;
          }
          return el;
        });
      
      const block = (
        <Section key={index} id={string_to_slug(section.title)} setActiveSection={setActiveSection}>
          {section.displayTitle && (
            <Title text={section.title} />
          )}
          {contentBlocks}
        </Section>
      );

      return block;
    });

  return (
    <Layout>
      <SEO title={title} description={site.description} keywords={site.keywords} />
      <Hero {...hero} title={title} />
      <ContentContainer>
        <Subnav items={pageSections} activeSection={activeSection} />
        <Standfirst text={_rawStandfirst}/>
        {sections}
        <Footer pagination={pagination} hideForm />
      </ContentContainer>
    </Layout>
  );
};

export default ArchitecturePage;
