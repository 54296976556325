import React, { useEffect } from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import * as styles from "./hero.module.css";

const renderImage = image => {
  let img = null;
  if (image.asset) {
    const imageData = getGatsbyImageData(
      image,
      { maxWidth: 2880 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className={styles.image}
        image={imageData}
        alt={image.alt}
        loading="eager"
        backgroundColor="#f0eeea"
      />
    );
  }
  return img;
};

const onClickHandler = () => {
  window.scroll({
    behavior: 'smooth',
    top: window.innerHeight - 84
  });
};

const Hero = ({ image, videoUrl, title }) => {
  let hero;
  let videoNode;

  if (videoUrl) {
    useEffect(() => {
      const video = videoNode;
      // video can play
      video.oncanplay = function() {
        video.classList.add(`${styles.canPlay}`);
      }
    });
    
    hero = (
      <video
        ref={node => (videoNode = node)}
        className={styles.video}
        playsInline
        autoPlay
        loop
        muted
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    ); 
  } else {
    hero = renderImage(image);
  }

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          {hero}
        </div>
        <div className={styles.tint}>
          <div></div>
        </div>
        <h1 className={styles.title}>{title}</h1>
      </div>
      <div className={styles.arrow} onClick={() => onClickHandler()}></div>
    </React.Fragment>
  );
};

export default Hero;