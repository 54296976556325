import React, { useRef } from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import Modal from "./modal";

import * as styles from "./fullscreen-image-viewer.module.css";

const FullScreenImageViewer = ({ image }) => {
  const mapModalRef = useRef();

  return (
    <div className={styles.root}>
      <button onClick={() => mapModalRef.current.openModal()} className={cn(styles.openButton, styles.button)}>
        <svg x="0" y="0" viewBox="0 0 25.5 25.3">
          <path d="M12.6 12.9L24.9.6m-.2 5.1l.2-5-5 .2M.6.6l12.2 12.2M5.7.8L.7.6l.2 5m11.7 6.9l12.2 12.2m-5-.2l5 .2-.2-5m-11.7-7.2L.6 24.7m.2-5l-.2 5 5-.2" fill="none" stroke="#9d9170" stroke-width=".7"/>
        </svg>
      </button>
      <Modal ref={mapModalRef}>
        <div className={styles.imageContainer}>
          {image && image.asset && (
            <img
              src={imageUrlFor(buildImageObj(image))
                .width(1440)
                .url()}
              alt={image.alt}
            />
          )}
        </div>
        <button onClick={() => mapModalRef.current.closeModal()} className={styles.button}>
          <svg x="0" y="0" viewBox="0 0 25.5 25.3">
            <path d="M12.6 12.9L24.9.6m-.2 5.1l.2-5-5 .2M.6.6l12.2 12.2M5.7.8L.7.6l.2 5m11.7 6.9l12.2 12.2m-5-.2l5 .2-.2-5m-11.7-7.2L.6 24.7m.2-5l-.2 5 5-.2" fill="none" stroke="#9d9170" stroke-width=".7"/>
          </svg>
        </button>
      </Modal>
    </div>
  );
};

export default FullScreenImageViewer;