import React, { useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import Modal from "./modal";

import * as styles from "./map.module.css";

const Map = ({ image }) => {
  const mapModalRef = useRef();

  return (
    <div className={styles.root}>
      <TransformWrapper wheel={{disabled: true}}>
        <TransformComponent>
          {image && image.asset && (
            <img
              className={styles.image}
              src={imageUrlFor(buildImageObj(image))
                .width(4320)
                .url()}
              alt="A vector map of the local area"
            />
          )}
        </TransformComponent>
      </TransformWrapper>
      <button className={styles.button} onClick={() => mapModalRef.current.openModal()}>
        <svg x="0" y="0" viewBox="0 0 25.5 25.3">
          <path d="M12.6 12.9L24.9.6m-.2 5.1l.2-5-5 .2M.6.6l12.2 12.2M5.7.8L.7.6l.2 5m11.7 6.9l12.2 12.2m-5-.2l5 .2-.2-5m-11.7-7.2L.6 24.7m.2-5l-.2 5 5-.2" fill="none" stroke="#9d9170" stroke-width=".7"/>
        </svg>
      </button>
      <Modal ref={mapModalRef}>
        <TransformWrapper wheel={{disabled: true}}>
          <TransformComponent>
            {image && image.asset && (
              <img
                className={styles.image}
                src={imageUrlFor(buildImageObj(image))
                  .width(4320)
                  .url()}
                alt="A vector map of the local area"
              />
            )}
          </TransformComponent>
        </TransformWrapper>
        <button onClick={() => mapModalRef.current.closeModal()} className={styles.button}>
          <svg x="0" y="0" viewBox="0 0 25.5 25.3">
            <path d="M12.6 12.9L24.9.6m-.2 5.1l.2-5-5 .2M.6.6l12.2 12.2M5.7.8L.7.6l.2 5m11.7 6.9l12.2 12.2m-5-.2l5 .2-.2-5m-11.7-7.2L.6 24.7m.2-5l-.2 5 5-.2" fill="none" stroke="#9d9170" stroke-width=".7"/>
          </svg>
        </button>
      </Modal>
    </div>
  );
}

export default Map;