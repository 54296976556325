import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import { cn } from "../lib/helpers";
import BlockText from "./block-text";
import FullScreenImageViewer from "./fullscreen-image-viewer";
import * as styles from "./image-composition-text.module.css";

const renderImage = image => {
  let img = null;
  if (image.asset) {
    const imageData = getGatsbyImageData(
      image,
      { maxWidth: 2880 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        image={imageData}
        alt={image.alt}
        backgroundColor="#f0eeea"
      />
    );
  }
  return img;
};

const ImageCompositionText = ({ imageOne, imageTwo, text }) => {
  const imageOneAsset = renderImage(imageOne);
  const imageTwoAsset = renderImage(imageTwo);

  return (
    <div className={styles.root}>
      <div>
        {imageOneAsset && (
          <div className={styles.image}>
            {imageOneAsset}
            {imageOne.caption && (
              <div className="caption">
                <p>{imageOne.caption}</p>
              </div>
            )}
            <FullScreenImageViewer image={imageOne} />
          </div>
        )}

        <div className={cn(styles.text, 'indented-copy')}>
          <BlockText blocks={text} />
        </div>
      </div>
      <div>
        {imageTwoAsset && (
          <div>
            {imageTwoAsset}
            {imageTwo.caption && (
              <div className={cn(styles.mobCaption, "caption")}>
                <p>{imageTwo.caption}</p>
              </div>
            )}
            <FullScreenImageViewer image={imageTwo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCompositionText;