import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);
  const showHeader = props.showHeader;
  
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          );
        }
        return (
          <Layout
            {...props}
            showNav={showNav}
            showHeader={showHeader}
            siteTitle={data.site.title}
            setShowNav={setShowNav}
            invertTheme={props.invertTheme}
            backgroundColor={props.backgroundColor}
          />
        );
      }}
    />
  );
}

export default LayoutContainer;
