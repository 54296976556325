import React, { useState, useEffect, useRef } from "react";
import BlockText from "./block-text";
import * as styles from "./standfirst.module.css";

const Standfirst = ({ text }) => {
  const [opacity, setOpacity] = useState(1);
  const standfirstEl = useRef(null);
  const isBrowser = typeof window !== "undefined";
  const isMobile = isBrowser ? window.innerWidth < 900 : null;

  const handleScroll = () => {
    if (isMobile) {
      setOpacity(Math.max(0, 1 - (standfirstEl.current.offsetTop - window.innerWidth) / 400));
    } else {
      setOpacity(Math.max(0, 1 - (standfirstEl.current.offsetTop - window.innerHeight) / 400));
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.root} style={{opacity}} ref={standfirstEl}>
      <BlockText blocks={text} />
    </div>
  );
}

export default Standfirst;