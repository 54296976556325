import React from "react";
import * as styles from "./subnav.module.css";

function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

const onClick = (id) => {
  const target = document.getElementById(id);

  window.scroll({
    behavior: 'smooth',
    top: target.offsetTop - 200
  });
}

const Subnav = ({ items, activeSection }) => {
  return (
    <nav className={styles.root}>
      <ul>
        {items.map(({ title }) => {
          const id = string_to_slug(title);

          return (
            <li key={id} onClick={() => onClick(id)} className={activeSection == id ? styles.isActive : ''}>
              {title}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Subnav;
