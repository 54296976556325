import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { cn } from "../lib/helpers";

import * as styles from "./apartment-modal.module.css";

const ApartmentModal = ({ filteredApartmentNodes, activeApartmentId, closeModal }) => {
  const activeApartment = filteredApartmentNodes.filter(apartment => apartment.id === activeApartmentId)[0];

  return (
    <TransformWrapper
      defaultScale={1}
      defaultPositionX={200}
      defaultPositionY={100}
    >
      {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
        <div className={styles.root}>
          <TransformComponent>
            <div className={styles.container}>
              {activeApartment && activeApartment.plan && (
                <img src={activeApartment.plan.asset.url} alt={activeApartment.residence} />
              )}
            </div>
          </TransformComponent>
          {activeApartment && activeApartment.download && (
            <div className={styles.extras}>
              <a href={activeApartment.download.asset.url} target="_blank" rel="noopener noreferrer" download className={cn(styles.button, styles.buttonText)}>
                Download plan
              </a>
            </div>
          )}
          <div className={styles.controls}>
            <button type="button" onClick={() => zoomIn()} className={cn(styles.button, styles.zoomIn, styles.controlsButton)}>
              <span></span>
              <span></span>
            </button>
            <button type="button" onClick={() => zoomOut()} className={cn(styles.button, styles.controlsButton)}>
              <span></span>
            </button>
            <button type="button" onClick={() => closeModal()} className={cn(styles.button, styles.buttonText)}>
              <span>Exit plan view</span>
            </button>
          </div>
        </div>
      )}
    </TransformWrapper>
  );
}

export default ApartmentModal;