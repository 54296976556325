import React from "react";
import { navigate } from "gatsby";

import * as styles from './back-button.module.css';

export default function BackButton() {
  return (
    <button type="button" onClick={() => navigate(-1)} className={styles.root}>
      <span>Go Back</span>
    </button>
  );
}