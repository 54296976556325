import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import * as styles from "./large-image.module.css";

const renderImage = image => {
  let img = null;
  if (image.asset) {
    const imageData = getGatsbyImageData(
      image,
      { maxWidth: 2880 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        image={imageData}
        alt={image.alt}
        backgroundColor="#f0eeea"
      />
    );
  }
  return img;
};

const LargeImage = ({ image }) => {
  const imageAsset = renderImage(image);
  return (
    <div className={styles.root}>
      {imageAsset}
      {image.caption && (
        <div className="caption">
          <p>{image.caption}</p>
        </div>
      )}
    </div>
  );
};

export default LargeImage;