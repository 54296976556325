import React, { useState, useEffect, useRef } from "react";

import * as styles from "./title.module.css";

const Title = ({ text }) => {
  // const [opacity, setOpacity] = useState(1);
  // const ref = useRef();

  // useEffect(() => {
  //   const refOffsetTop = ref.current.offsetTop;

  //   const handleScroll = () => {
  //     const scrollY = window.pageYOffset;
  //     setOpacity(Math.max(0, 1 - (scrollY - refOffsetTop) / 500));
  //   }

  //   window.addEventListener('scroll', handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div className={styles.root}>
      <h2>{text}</h2>
    </div>
  );
}

export default Title;
