// extracted by mini-css-extract-plugin
export const root = "availability-module--root--1Mp5f";
export const main = "availability-module--main--1lUus";
export const elevation = "availability-module--elevation--1cNY4";
export const isActive = "availability-module--isActive--1NEnl";
export const elevationButtons = "availability-module--elevationButtons--1WZ7l";
export const button = "availability-module--button--1Rprc";
export const table = "availability-module--table--vMsSk";
export const tableHead = "availability-module--tableHead--22tNv";
export const tableBody = "availability-module--tableBody--1owlp";
export const tableRow = "availability-module--tableRow--1WMD1";
export const buttons = "availability-module--buttons--3CgPJ";
export const disabled = "availability-module--disabled--3Znnu";
export const footer = "availability-module--footer--17ujO";
export const modal = "availability-module--modal--kjWX1";
export const mobileRules = "availability-module--mobileRules--1yzKD";
export const residence = "availability-module--residence--35rIq";
export const bedrooms = "availability-module--bedrooms--Tiyls";
export const bathrooms = "availability-module--bathrooms--ysqSf";
export const interior = "availability-module--interior--3U3k_";
export const exterior = "availability-module--exterior--3aZSa";
export const charges = "availability-module--charges--6IBXl";
export const taxes = "availability-module--taxes--1xEG-";
export const apartmentFilter = "availability-module--apartmentFilter--3tmcO";
export const desktopView = "availability-module--desktopView--2_w7d";
export const mobileView = "availability-module--mobileView--3AcqE";