import React, { useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import Modal from "./modal";

import * as styles from "./figure.module.css";

export function Figure({ node }) {
  const mapModalRef = useRef();

  if (!node.asset) {
    return null;
  }

  const imageData = getGatsbyImageData(node.asset, { maxWidth: 675 }, clientConfig.sanity);
  
  return (
    <figure className={styles.root}>
      <GatsbyImage 
        image={imageData}
        alt={node.alt}
        backgroundColor="#f0eeea"
      />
      {node.caption && <figcaption>{node.caption}</figcaption>}
      <button onClick={() => mapModalRef.current.openModal()} className={styles.button}>
        <svg x="0" y="0" viewBox="0 0 25.5 25.3">
          <path d="M12.6 12.9L24.9.6m-.2 5.1l.2-5-5 .2M.6.6l12.2 12.2M5.7.8L.7.6l.2 5m11.7 6.9l12.2 12.2m-5-.2l5 .2-.2-5m-11.7-7.2L.6 24.7m.2-5l-.2 5 5-.2" fill="none" stroke="#9d9170" stroke-width=".7"/>
        </svg>
      </button>
      <Modal ref={mapModalRef}>
        <GatsbyImage 
          image={imageData}
          alt={node.alt}
          backgroundColor="#f0eeea"
        />
        <button onClick={() => mapModalRef.current.closeModal()} className={styles.button}>
          <svg x="0" y="0" viewBox="0 0 25.5 25.3">
            <path d="M12.6 12.9L24.9.6m-.2 5.1l.2-5-5 .2M.6.6l12.2 12.2M5.7.8L.7.6l.2 5m11.7 6.9l12.2 12.2m-5-.2l5 .2-.2-5m-11.7-7.2L.6 24.7m.2-5l-.2 5 5-.2" fill="none" stroke="#9d9170" stroke-width=".7"/>
          </svg>
        </button>
      </Modal>
    </figure>
  );
}
