import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Footer from "../components/footer";
import BlockText from "../components/block-text";

import * as styles from "../components/legal.module.css";

export const query = graphql`
  query LegalPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    information: sanityInformation {
      _rawLegal
    }
  }
`;

const LegalPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const information = (data || {}).information;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { _rawLegal } = information;

  return (
    <Layout invertTheme>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div className="page-content-wrapper">
        <div className={styles.root}>
          <BlockText blocks={_rawLegal} />
        </div>
      </div>
      <Footer hideForm hideButton />
    </Layout>
  );
};

export default LegalPage;
